import React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../../../container/main'
import SEO from '../../../components/seo'
import Container from '../../../components/container'
import ContentBannerWithCTA from '../../../components/content-banner-with-cta'
import { heroContainer, contentBlock } from './index.module.scss'
import { UseCaseBlock, UseCaseCategory, UseCaseHero, UseCasesContainer, UseCaseTeaser } from '../../../components/content-use-cases'
import ContentBlockSecondary from '../../../components/content-block-secondary'
import ContentBlockSmall from '../../../components/content-block-small'
import ContentBlockLargeWithGallery from '../../../components/content-block-large-gallery'
import Subheadline from '../../../components/content-subheadline'

const IndexPage = ({ data, location }) => (
  <MainContainer>
    <SEO title="SaaS & Link Management" />

    <Container fullWidth style={{ background: 'var(--primary)' }}>
      <UseCaseCategory title="SaaS & Link Management" />
    </Container>

    <Container>
      <UseCaseHero
        title="Manage all apps your employees love in a Digital Hub"
        description="In addition to Microsoft 365 or Google Workspace, companies use a variety of different SaaS apps, such as CRM, ERP, HR solutions and much more. In a medium-sized company, an average of 88 apps are in use. It often happens that employees or entire departments use appropriate SaaS solutions independently to solve specific issues. As result, 48% of work apps are not rolled out by the IT department. A Digital Hub simplifies daily work with a variety of apps for your employees. At the same time IT manager get advanced insights to the usage of its SaaS tools to improve their overall SaaS Management."
        actionLinkText="Get started – It's free!"
        actionLinkIsExternal
        actionLink="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=hero&utm_campaign=signup-basaas-2022&utm_content=lp-saas-app-cloud-link-management"
        demoButton
      />
    </Container>

    <Container style={{ padding: '80px 0 0' }}>
      <Subheadline headline="Your digital headquarters for efficient app management" />
      <span style={{ display: 'block', marginTop: '-8px', fontSize: '16px', fontWeight: '600', color: 'var(--primary)' }}>
        Connect all your apps and employees into one solution and support your people with a Digital Hub to get the most out of your business.
      </span>
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Share Apps',
            title: 'Share apps and knowledge easily',
            description: `Create corporate apps and distribute approved apps to your colleagues. Access a comprehensive app portfolio and share any internal app or website as well.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-apps.png" alt="Share apps and knowledge easily" placeholder="blurred" />,
          },
        ]}
        alignVertically
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Remote Work',
            title: 'Optimize Remote Work for colleagues',
            description: `Provide your employees with a Digital Hub where they can find everything they need for their daily work in one place and enable successful remote working.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-myday.png" alt="Optimize Remote Work for colleagues" placeholder="blurred" />,
          },
        ]}
        alignVertically
        // reverse
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Employee Self Service',
            title: 'Save resources by employee self-service',
            description: `With the Digital Hub, you offer your employees an excellent digital employee experience. This way, they can use Basaas autonomously and usually do not need any support.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-groups.png" alt="Save resources by employee self-service" placeholder="blurred" />,
          },
        ]}
        alignVertically
      />
    </Container>

    <Container className={contentBlock}>
      <ContentBlockLargeWithGallery
        // title="Work across apps and teams"
        // highlightColor="#FAD34C"
        content={[
          {
            linkTitle: 'Usage Analytics',
            title: 'Analyze and optimize SaaS usage',
            description: `Gain meaningful insights into your company’s SaaS usage. A centralized app management creates transparency and reduces shadow IT.`,
            media: <StaticImage src="../../../assets/images/basaas-2022-analytics.png" alt="Analyze and optimize SaaS usage" placeholder="blurred" />,
            // comingSoon: true,
          },
        ]}
        alignVertically
        // reverse
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '160px 0 80px', marginTop: '160px' }}>
      <h2 style={{ fontSize: '24px', margin: '0 0 40px', fontWeight: 800 }}>Explore how to optimize your business for</h2>
      <UseCaseBlock location={location.pathname} />
    </Container>

    {/* BLOCK SECURITY  */}
    <Container style={{ background: 'var(--base-background)' }}>
      <ContentBlockSecondary
        headline="Enterprise productivity at the highest security level"
        description="The Basaas security concept is based on international best in class standards and EU requirements and guarantees the highest enterprise security level."
        // actionLinkText="Learn more"
        // actionLink="/tasks"
        item_01={
          <ContentBlockSmall
            key="1"
            headline="Fully GDPR compliant"
            description="Basaas is fully comply with European data security regulations including GDPR. For European companies, we guarantee that data storage takes place exclusively in the EU."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../../../assets/feature-icons/gdpr-compliant.png" alt="Fully GDPR compliant" placeholder="blurred" />}
          />
        }
        item_02={
          <ContentBlockSmall
            key="2"
            headline="Proven enterprise security"
            description="At Basaas, we take our customers’ security very seriously and have extensive experience serving enterprise clients with complex security requirements."
            // actionLinkText="Learn more"
            // actionLink="/workplace"
            media={<StaticImage src="../../../assets/feature-icons/proven-enterprise-security.png" alt="Proven enterprise security" placeholder="blurred" />}
          />
        }
      />
    </Container>

    <Container style={{ background: 'var(--base-background)', padding: '80px 0' }}>
      <ContentBannerWithCTA />
    </Container>
  </MainContainer>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        teamsCount
      }
    }
  }
`
